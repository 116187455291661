import React from 'react'
import { css } from '@emotion/react'
import Slick from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import propTypes from './propTypes'

const options = {
  slidesToShow: 1,
  slidesToScroll: 1,
}

const carousel = css`
  background: #eee;
`

const carouselCell = css`
  display: block;
`

const ClientImageCarousel = ({ images }) => (
  <Slick {...options} css={carousel}>
    {images.map(image => (
      <img
        key={image.fluid.src}
        css={carouselCell}
        alt={image.alt}
        srcSet={image.fluid.srcSet}
        src={image.fluid.src}
        sizes="100w"
      />
    ))}
  </Slick>
)

ClientImageCarousel.propTypes = propTypes

export default ClientImageCarousel
