import React from 'react'
import Img from 'gatsby-image'

import propTypes from './propTypes'

const ImageList = ({ images }) => (
  <ul css={{ listStyle: 'none', padding: 0 }}>
    {images.map((image, i) => (
      <li key={i}>
        <Img {...image} />
      </li>
    ))}
  </ul>
)

ImageList.propTypes = propTypes

const StaticImageCarousel = ImageList

export default StaticImageCarousel
