import PropTypes from 'prop-types'

const propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fluid: PropTypes.object.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default propTypes
