/* eslint-disable react/no-did-mount-set-state */

import { compose, lifecycle, branch, renderComponent } from 'recompose'

import propTypes from './propTypes'
import ClientImageCarousel from './ClientImageCarousel'
import StaticImageCarousel from './StaticImageCarousel'

const ImageCarousel = compose(
  lifecycle({
    state: {
      mounted: false,
    },
    componentDidMount() {
      this.setState({ mounted: true })
    },
  }),
  branch(({ mounted }) => mounted, renderComponent(ClientImageCarousel))
)(StaticImageCarousel)

ImageCarousel.propTypes = propTypes

export default ImageCarousel
