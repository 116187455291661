import React from 'react'

import { useSiteMetadata } from '../../queries'
import { OrgSchema } from '../JsonLd'

const useOrgSchemaData = () => {
  const siteMetadata = useSiteMetadata()
  return siteMetadata
}

const OrgMetadata = React.memo(() => {
  const orgData = useOrgSchemaData()
  return <OrgSchema {...orgData} />
})

OrgMetadata.displayName = 'OrgMetadata'

export default OrgMetadata
