import React from 'react'
import { graphql } from 'gatsby'
import { FluidObject, GatsbyImageProps } from 'gatsby-image'

import { useReviewParaphrasesData } from '../queries'
import DefaultTemplate, {
  DefaultTemplateProps,
  SiteMeta,
  PageContent,
} from '../components/DefaultTemplate'
import ImageCarousel from '../components/ImageCarousel'
import TestimonialsList from '../components/TestimonialsList'
import OrgMetadata from '../components/metadata/OrgMetadata'

interface PageProps extends DefaultTemplateProps {
  galleryImages: GatsbyImageProps[]
}

const IndexPage = ({ galleryImages, ...props }: PageProps) => {
  const paraphrases = useReviewParaphrasesData()

  return (
    <>
      <DefaultTemplate {...props}>
        <ImageCarousel images={galleryImages} />
        <section>
          <h2>Reviews</h2>
          <TestimonialsList paraphrases={paraphrases} />
        </section>
      </DefaultTemplate>
      <OrgMetadata />
    </>
  )
}

export const pageQuery = graphql`
  query HomepageQuery {
    site {
      ...SiteMeta
    }
    page: page(path: { eq: "/" }) {
      ...PageContent
      markdown {
        frontmatter {
          gallery {
            alt
            file {
              childImageSharp {
                fluid(maxWidth: 140, maxHeight: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

interface IndexPageContent {
  markdown: {
    frontmatter: {
      gallery: Array<{
        alt: string
        file: {
          childImageSharp: {
            fluid: FluidObject
          }
        }
      }>
    }
  }
}

interface PageData {
  site: SiteMeta
  page: PageContent & IndexPageContent
}

const IndexContainer = ({ data }: { data: PageData }) => {
  const props = DefaultTemplate.selectProps({ data })

  const galleryImages = data.page.markdown.frontmatter.gallery.map(({ file, alt }) => ({
    ...file.childImageSharp,
    alt,
  }))

  return <IndexPage {...props} galleryImages={galleryImages} />
}

export default IndexContainer
