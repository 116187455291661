import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

const Testimonial = ({ body, review }) => (
  <figure>
    <blockquote>
      <p>{body}</p>
    </blockquote>
    <figcaption>
      <div>{review.authorName}</div>
      <div>
        <Link to={`/reviews/#review-${review.id}`}>Read full review</Link>
      </div>
    </figcaption>
  </figure>
)

Testimonial.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  body: PropTypes.string.isRequired,
  review: PropTypes.object.isRequired,
}

const TestimonialsList = ({ paraphrases }) => (
  <ul>
    {paraphrases.map((paraphrase, i) => (
      <li key={i}>
        <Testimonial {...paraphrase} />
      </li>
    ))}
  </ul>
)

TestimonialsList.propTypes = {
  paraphrases: PropTypes.arrayOf(PropTypes.shape(Testimonial.propTypes)).isRequired,
}

export default TestimonialsList
